import Animation from "../../animation/Animation";

export const OurMissionAndTech = () => {
  return (
    <div className="flex flex-col pt-28 lg:pt-[120px] justify-evenly  lg:border-white lg:-mt-[240px] lg:bg-white lg:rounded-t-[180px]">
      <div className="px-8 lg:px-20 lg:flex lg:items-start lg:justify-evenly lg:w-full lg:mt-20">
        <div className="pb-14 lg:w-1/3 ">
          <h2 className="pb-1 text-4xl lg:text-[45px] lg:leading-[50px] text-dark-purple font-bold">
            Our Mission
          </h2>
          <h3 className="py-4 text-dark-purple text-xl lg:text-[22.5px] lg:leading-[35px] font-bold">
            Everyone deserves to feel safe, with ZAHN that becomes possible.
          </h3>
          <p className="text-dark-grey text-sm lg:text-[17.5px] lg:leading-[25px] font-semibold pt-4">
            ZAHN is on a mission to bring Londoners the first free mobile app
            which will transform the way we feel about our safety.
          </p>
        </div>
        <div className="lg:pb-14 lg:w-1/3">
          <h2 className="pb-1 text-4xl lg:text-[45px] lg:leading-[50px] text-dark-purple font-bold">
            Our Technology
          </h2>
          <h3 className="py-4 text-dark-purple font-bold text-xl lg:text-[22.5px] lg:leading-[35px]">
            ZAHN is leading the way in technological innovation.
          </h3>
          <p className="text-dark-grey pt-4 text-sm lg:text-[17.5px] lg:leading-[25px] font-semibold">
            ZAHN believes in the power of technological innovation as a
            transformative solution for our personal and community safety.
            Combining some of the most innovative technologies with
            forward-thinking leadership.
          </p>
        </div>
      </div>
      <div className="py-20 sm:my-4 lg:py-10 lg:my-0 lg:mb-10 flex flex-col justify-center">
        <Animation />
      </div>
    </div>
  );
};
